import styled from '@emotion/styled';
import _ from 'lodash';

import { PSection } from 'src/interface/section';
import { ITags } from 'src/interface/service';
import { Media } from 'src/interface/media';

import CardFull from 'src/components/cards/card-full';
import SectionHeader from 'src/components/sections/section-header';
import useResponsive from 'src/utils/devices';
import { convertObjPath } from 'src/utils/helper';
import useSWR from 'swr';
import { GET } from 'src/services';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import SectionFullTabs from 'src/components/sections/section-full-tab';
import { SHOW_COMMEMORATE } from 'src/constants';
import SectionFood from 'src/section-events/events-013';
import SectionPower from 'src/section-events/events-015';

type Props = {
  block1?: PSection['block1'];
  block2?: PSection['block2'];
  block3?: PSection['block3'];
  dataTags?: { data: ITags[] };
  eventDataLayer?: IDataLayerGlobal;
  dataSoft?: any;
};

const SectionThree = ({ eventDataLayer, dataSoft }: Props) => {
  const responsive: Media = useResponsive();
  const payload: any = {
    two: {
      block1: 6,
      block2: 6,
      block3: 3
    }
  };
  const fetcher = (url: any) => GET(url);
  const { data } = useSWR(`/api/v1.0/home/section-two${convertObjPath(payload.two)}`, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: true
  });
  // DESKTOP
  const block1 = data && data?.block1;
  const block2 = data && data?.block2;
  const block3 = data && data?.block3;

  // MOBILE
  let cloneDataList: any = [block1, block2, block3];

  return (
    <>
      {!_.isEmpty(block1) && (
        <SectionWrapper id={`section-5`} className={` wrapper-1`} style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }} >
          <SectionFood dataSoft={dataSoft} />
          {responsive.isLaptop && (
            <div className='block-wp container'>
              <div className='block-1-w1' >
                <SectionHeader data={block1?.title} link={block1?.link} />
                <div className='card-list desktop'>
                  {_.map(block1?.data?.slice(0, 1), (item, i) => (
                    <div className='card-item' key={i}>
                      <CardFull
                        data={item}
                        showBlurb={false}
                        showFooter={false}
                        showBadge={false}
                        // ! DATALAYER
                        eventDataLayer={{
                          ...eventDataLayer,
                          section: 'section-5',
                          position: 'ใต้:section-6',
                          data: {
                            block: '1',
                            title: item?.title,
                            heading: block1?.title,
                            index: i + 1,
                            ...eventDataLayer?.data
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className='block-2-w1'>
                <SectionHeader data={block2?.title} link={block2?.link} />

                <div className='card-list desktop'>
                  {_.map(block2?.data?.slice(0, 1), (item, i) => (
                    <div className='card-item' key={i}>
                      <CardFull
                        data={item}
                        showBlurb={false}
                        showFooter={false}
                        showBadge={false}
                        // ! DATALAYER
                        eventDataLayer={{
                          ...eventDataLayer,
                          section: 'section-5',
                          position: 'ใต้:section-6',
                          data: {
                            block: '2',
                            title: item?.title,
                            heading: block2?.title,
                            index: i + 1,
                            ...eventDataLayer?.data
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className='block-3-w1'>
                <SectionHeader data={block3?.title} link={block3?.link} fontColor={'#000'} />
                <div className='card-list desktop'>
                  {_.map(block3?.data?.slice(0, 1), (item, i) => (
                    <div className='card-item' key={i}>
                      <CardFull
                        data={item}
                        showBlurb={false}
                        showFooter={false}
                        showBadge={false}
                        // ! DATALAYER
                        eventDataLayer={{
                          ...eventDataLayer,
                          section: 'section-5',
                          position: 'ใต้:section-6',
                          data: {
                            block: '3',
                            title: item?.title,
                            heading: block2?.title,
                            index: i + 1,
                            ...eventDataLayer?.data
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {responsive.isTabletMini && (
            <div className='container'>
              {!_.isEmpty(cloneDataList) && <SectionFullTabs
                designOne={true}
                data={cloneDataList}
                // ! DATALAYER
                eventDataLayer={{
                  ...eventDataLayer,
                  section: 'section-5',
                  position: 'ใต้:section-4'
                }}
              />}
            </div>
          )}
          <SectionPower dataSoft={dataSoft} />
        </SectionWrapper>
      )}
    </>
  );
};

export default SectionThree;

const SectionWrapper = styled.section`
  position: relative;
  overflow: hidden;
  &.wrapper-1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    .block-wp {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      
      padding: 30px 15px !important;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }
    .block-1-w1,
    .block-2-w1,
    .block-3-w1 {
      .card-list {
        margin-top: 10px;
      }
    }
  }
`;
