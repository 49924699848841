import styled from '@emotion/styled';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { GET } from 'src/services';
import CardFull from 'src/components/cards/card-full';

import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import { convertDatasets } from 'src/utils/datasets';
import { convertObjPath } from 'src/utils/helper';

import { useRouter } from 'next/router';
import useResponsive from 'src/utils/devices';
import { Media } from 'src/interface/media';
import Carousels from './carousels';
import SectionHeader from 'src/components/sections/section-header';

const SectionFood = ({ dataSoft }: any) => {
  const router = useRouter();
  if (_.isEmpty(dataSoft)) return null;

  let linkMore: any = '';
  let link: any = '';
  let tag: any = [];
  let keysTag: any = '';

  const softData = {
    item1: convertDatasets(dataSoft[0], 'image-left-sce1', true),
    item2: convertDatasets(dataSoft[0], 'image-bg-sce1', true),
    item3: convertDatasets(dataSoft[0], 'image-right-sce1', true),
    item4: convertDatasets(dataSoft[0], 'title-sce1', true),
    item5: convertDatasets(dataSoft[0], 'data-sce1', true),
    item6: convertDatasets(dataSoft[0], 'image-bg-sce1-mb', true),
    item7: convertDatasets(dataSoft[0], 'open-sce1', true),
    item8: convertDatasets(dataSoft[0], 'title-header', true),
    item9: convertDatasets(dataSoft[0], 'switch-key-sce1', true)
  };

  if (softData.item9 === 'tag') {
    if (softData.item5.indexOf('/') !== -1) {
      tag = softData.item5.split('/');
      keysTag = tag[1];
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      keysTag = softData.item5;
    }

    linkMore = `/api/search/tag${convertObjPath({
      name: keysTag
    })}&limit=3`;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    link = `/tags/${keysTag}`;
  } else if (softData.item9 === 'category') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    linkMore = `/api/v1.0/categories${softData.item5}`;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    link = `/category${softData.item5}`;
  }

  const [resI, setResI]: any = useState();
  const responsive: Media = useResponsive();

  useEffect(() => {
    const fetchData = async () => {
      if (!_.isEmpty(softData?.item5) && softData.item7 === 'true') {
        const resI = await GET(
          `${linkMore}`,
          false,
          false
        );
        setResI(resI);
      }
    };

    fetchData()
      .catch(console.error);
  }, []);


  const resData = !_.isEmpty(resI) ? resI.slice(0, 3) : [];

  return (
    <>
      {!_.isEmpty(resData) && (
        <>
          {!_.isEmpty(softData?.item8) && <SectionHeader data={`${softData?.item8}`} className='sub-menu center' style='default' />}

          <SectionWrapper backgroundImage={softData?.item2} backgroundImageMb={softData?.item6}>
            <div>
              {!_.isEmpty(softData?.item1) && <img className='image-left-sce1' src={softData?.item1} alt={softData?.item4} title={softData?.item4} width={'100%'} height={'100%'} />}
              <div className='container detail-sec-1'>
                <h3 className='title-head'>{softData?.item4}</h3>
                <p className='read-more'>
                  <a href={`${link}`}>ดูทั้งหมด</a>
                </p>
                {responsive.isMobile && (
                  <Carousels dots={true} mobileSlideShow={1} slideShow={1} showArrow={true} autoplaySpeed={6000}>
                    {_.map(resData, (item, i) => (
                      <div className='card-item' key={i}>
                        <CardFull
                          data={item}
                          showFooter={false}
                          showBlurb={false}
                          showTitle={true}
                          showBadge={false}
                          // ! DATALAYER
                          eventDataLayer={{
                            type: DATALAYER_TYPE.TRACK_POSITION,
                            router: router.pathname,
                            section: 'section-5',
                            position: 'ใต้:section-4',
                            data: {
                              block: '1',
                              heading: softData?.item4,
                              title: item?.title,
                              index: i + 1
                            }
                          }}
                        />
                      </div>
                    ))}
                  </Carousels>
                )}
                {responsive.isLaptop && (
                  <div className='card-list'>
                    {_.map(resData, (item, i) => (
                      <div className='card-item' key={i}>
                        <CardFull
                          data={item}
                          showFooter={false}
                          showBlurb={false}
                          showTitle={true}
                          showBadge={false}
                          // ! DATALAYER
                          eventDataLayer={{
                            type: DATALAYER_TYPE.TRACK_POSITION,
                            router: router.pathname,
                            section: 'section-5',
                            position: 'ใต้:section-4',
                            data: {
                              block: '1',
                              heading: softData?.item4,
                              title: item?.title,
                              index: i + 1
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {!_.isEmpty(softData?.item1) && <img className='image-right-sce1' src={softData?.item3} alt={softData?.item4} title={softData?.item4} width={'100%'} height={'100%'} />}
            </div>
          </SectionWrapper>
        </>

      )}
    </>
  );
};

export default SectionFood;

const SectionWrapper = styled.section<{
  backgroundImage: any;
  backgroundImageMb: any;
}>`
    background: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : ''} ;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    
    @media (max-width: 425px) {
      background-repeat: no-repeat;
      background-size: cover;
      background: ${props => props.backgroundImageMb ? `url(${props.backgroundImageMb})` : ''} ;
    }
    .image-left-sce1, .image-right-sce1 {
      width: 100%;
      max-width: 300px;
      position: absolute;
      bottom: -80px;
      height: auto;
      @media (max-width: 690px) {
        display: none;
      }
    }
    .image-left-sce1{
      left: -90px;
    }
    .image-right-sce1 {
        max-width: 350px;
        right: -90px;
    }

    .detail-sec-1 {
      text-align: center;
      padding: 30px 15px;
      font-weight: 300;
      font-size:44px;
      color: #774600;
      @media (max-width: 1024px) {
        font-size: 24px;
      }
      h3.title-head {
        line-height : 1;
        margin-bottom: 15px;
        filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.5));
      }
      .read-more {
        position: relative;
        font-size: 14px;
        font-weight: 700;
        text-align: right;
        z-index: 1;
        background-color:  var(--primary-color);
        border: 1px solid var(--primary-color);
        max-width: max-content;
        margin: 0 0 0 auto;
        /* margin: auto; */
        padding: 2px 15px;
        border-radius: 3px;
          a {
            color: #fff;
          }
          &:hover{
            a {
              color: var(--primary-color);
            }
            background-color: #fff;
            border: 1px solid var(--primary-color);
          }
          @media (max-width: 690px) {
            margin-bottom: 15px;
          }
      }
    }

    .card-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      padding: 12px 0 0 0;
      position: relative;
      z-index: 1;
      @media (max-width: 1024px) {
        padding: 30px 0 30px 0;
      }
    }
    .card-item {
      position: relative;
      z-index: 1;
      margin: 0 -5px;
      .card-full {
        .card-image {
          /* box-shadow: 0px 2px 10px 1px #00000033; */
          border-radius:5px;
          .card-content {
            /* height: 30%; */
            text-align: left;
          }
        }
      }
    }
`;